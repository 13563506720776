import { Children, useRef } from 'react';
import { ImageDivider, CustomText } from '@vfit/shared/atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { useDeviceType } from '@vfit/shared/hooks';
import { Fade } from 'react-awesome-reveal';
import { fonts } from '@vfit/shared/themes';
import { overrideProductList, useProductListTagging } from '@vfit/consumer/data-access';
import {
  ProductSolutionsContainer,
  ProductSolutionsText,
  DisclaimerContainer,
} from './productsSolutionsLite.style';
import { IProductsSolutionsProps } from './productsSolutionsLite.models';
import { getSlideSize } from './productsSolutionsLite.utils';
import ProductCardLite from '../ProductCardLite/productCardLite';

const ProductsSolutionsLite = ({
  solutions,
  containerStyle,
  classKey,
  onTrack,
  showCase,
  divider,
  disclaimer,
  isR02,
  panicModeConfiguration,
  globalPromo,
}: IProductsSolutionsProps) => {
  const productSolutionRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const haveMarketingLabel = !!solutions?.products.find((p) => !!p.marketingLabel);
  const { trackProductLink } = useProductListTagging();
  const products = overrideProductList(solutions?.products, panicModeConfiguration, globalPromo);

  const swiperSolutions = (type: 'slide' | 'cards') => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      spaceBetween={33}
      freeMode={false}
      mousewheel={{
        forceToAxis: true,
      }}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      style={{
        height: 'fit-content',
      }}
      onSlideChange={(swiper) => {
        if (isMobile && productSolutionRef?.current) {
          trackProductLink(products?.[swiper.activeIndex]);
        }
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        products?.map((product, index) => {
          let marginLeft = 0;
          if (index === 0) {
            if (isDesktop) marginLeft = 80;
            else if (isTablet) marginLeft = 32;
          }
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(index, isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                ...customSize,
              }}
            >
              <div>
                <ProductCardLite
                  cardIndex={index}
                  onTrack={onTrack}
                  showCase={showCase}
                  product={product}
                  classKey={classKey}
                  noMargin={isMobile}
                  haveMarketingLabel={haveMarketingLabel}
                  isR02={isR02}
                />
              </div>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  const listProductsMobile = () => {
    return (
      <div
        style={{
          padding: 30,
        }}
      >
        {Children.toArray(
          products?.map((product, index) => {
            return (
              <ProductCardLite
                onTrack={onTrack}
                showCase={showCase}
                cardIndex={index}
                product={product}
                classKey={classKey}
                noMargin={isMobile}
                haveMarketingLabel={haveMarketingLabel}
                isR02={isR02}
              />
            );
          })
        )}
      </div>
    );
  };

  if (!solutions.products || solutions?.products?.length === 0) return null;

  return (
    <>
      <ProductSolutionsContainer style={{ ...containerStyle }} ref={productSolutionRef}>
        {solutions.description && (
          <ProductSolutionsText>
            <CustomText text={solutions.description} />
          </ProductSolutionsText>
        )}
        {!isDesktop && listProductsMobile()}
        {isDesktop && swiperSolutions('slide')}
      </ProductSolutionsContainer>
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText
            text={disclaimer}
            lineHeight={22}
            size={16}
            textAlign="left"
            fontFamily={`${fonts.regular}`}
          />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default ProductsSolutionsLite;
