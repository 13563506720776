import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { ISoftEvents } from '@vfit/consumer/providers';
import {
  COEXISTENCE_COOKIE_KEY,
  IProduct,
  overrideProductList,
  isR02User,
} from '@vfit/consumer/data-access';
import { useCookie } from '@vfit/shared/hooks';
import ProductsList from '../../../ProductsList/productsList';
import { WidgetContainer } from '../../widgets.style';
import { IWidgetProductsList } from './widgetProductsList.models';
import ProductsListLite from '../../../ProductsListLite/productsListLite';

const WidgetProductsList = ({
  isLastWidget,
  onTrack,
  title,
  products,
  divider,
  disclaimer,
  hideTabs = false,
  panicModeConfiguration,
  globalPromo,
  enableLiteUI,
}: IWidgetProductsList) => {
  const [allProducts, setAllProducts] = useState(
    overrideProductList(products, panicModeConfiguration, globalPromo)
  );
  const [coexistenceCookie] = useCookie(COEXISTENCE_COOKIE_KEY, '');
  const ProductsListComponent = enableLiteUI ? ProductsListLite : ProductsList;

  useEffect(() => {
    setAllProducts(overrideProductList(products, panicModeConfiguration, globalPromo));
  }, [products]);

  if (typeof document !== 'undefined') {
    document.addEventListener(ISoftEvents.ADD_CUSTOM_OFFER, (event) => {
      const { detail } = event as CustomEvent as { detail: IProduct | null };
      if (detail && detail.offerId) {
        setAllProducts([...allProducts, detail]);
      }
    });
  }

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Fade direction="up" triggerOnce>
        <ProductsListComponent
          onTrack={onTrack || ''}
          title={title || ''}
          products={allProducts}
          divider={divider}
          disclaimer={disclaimer}
          classKey="offer"
          hideTabs={hideTabs}
          isR02={() => isR02User(coexistenceCookie || '')}
        />
      </Fade>
    </WidgetContainer>
  );
};

export default WidgetProductsList;
