import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ProductCardOfferLabel = styled.div<{
  haveLabel: boolean;
  textColor?: string;
  backgroundColor?: string;
}>`
  background-color: ${({ haveLabel, backgroundColor }) =>
    haveLabel ? backgroundColor || '#00697C' : 'transparent'};
  border-radius: 20px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  span {
    font-family: ${fonts.vodafone};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${({ textColor }) => textColor || colors.$ffffff};
    margin-top: 10px;
  }
`;

export const ProductCardOfferDoubleLabel = styled.div`
  display: flex;
  height: 74px;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
`;

export const FirstMarketingCard = styled.div<{ textColor?: string; backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#00697C'};
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px 20px 0 0;
  span {
    font-family: ${fonts.vodafone};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${({ textColor }) => textColor || colors.$ffffff};
    display: flex;
  }
`;

export const SecondMarketingCard = styled.div<{ textColor?: string; backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#00697C'};
  display: flex;
  padding: 10px 20px 10px 32px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-left: -20px;
  z-index: -1;
  border-radius: 0 20px 0 0;

  span {
    font-family: ${fonts.vodafone};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${({ textColor }) => textColor || colors.$ffffff};
    display: flex;
  }
`;
