import { purify } from '@vfit/shared/themes';
import { Children } from 'react';
import {
  Divider,
  FirstLabelContainer,
  LabelsContainer,
  OfferAllowanceLabel,
  Wrapper,
} from './productCardTopContainer.style';
import { IProductCardTopContainer } from './productCardTopContainer.models';
import PriceLite from '../../../PriceLite/priceLite';

const ProductCardTopContainer = ({
  offerAllowanceLabel,
  price,
  recurrence,
  originalPrice,
  priceTitle,
}: IProductCardTopContainer) => {
  const purifiedLabels = purify(offerAllowanceLabel);
  const slicedLabels = purifiedLabels.slice(1);

  return (
    <Wrapper>
      <FirstLabelContainer>
        <OfferAllowanceLabel
          dangerouslySetInnerHTML={{ __html: purifiedLabels[0]?.toUpperCase() || '' }}
        />
        <PriceLite
          price={price}
          originalPrice={originalPrice}
          recurrence={recurrence}
          priceLabel={priceTitle}
        />
      </FirstLabelContainer>
      {slicedLabels.length > 0 && (
        <LabelsContainer>
          <Divider>
            <span />
            +
            <span />
          </Divider>
          {Children.toArray(
            slicedLabels.map((slicedLabel) => (
              <OfferAllowanceLabel
                dangerouslySetInnerHTML={{ __html: slicedLabel?.toUpperCase() || '' }}
              />
            ))
          )}
        </LabelsContainer>
      )}
    </Wrapper>
  );
};

export default ProductCardTopContainer;

