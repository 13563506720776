import { fonts, purify } from '@vfit/shared/themes';
import { CustomText, Svg } from '@vfit/shared/atoms';
import { useState } from 'react';
import {
  OptionContainer,
  DisclaimerContainer,
  ModalContent,
  ModalTitle,
  ProductCardSpecItemAction,
  Title,
  Disclaimer,
} from './option.style';
import { IOption } from './option.models';
import { VfModal } from '@vfit/shared/components';

const Option = ({
  optionTitle,
  popup,
  color,
  optionDisclaimer,
  popupTitle,
  compactStyle,
  isGlobalPromo = false,
}: IOption) => {
  const [pOptionTitle, pOptionDisclaimer] = purify([optionTitle || '', optionDisclaimer || '']);
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });
  return (
    <>
      <OptionContainer compact={compactStyle}>
        <div style={{display:"flex", flexDirection:"column"}}>
        <DisclaimerContainer>
          {optionDisclaimer && (
            <Disclaimer dangerouslySetInnerHTML={{ __html: pOptionDisclaimer || '' }} />
          )}
        </DisclaimerContainer>
        <Title
          style={{ color: `${color}` }}
          dangerouslySetInnerHTML={{
            __html: pOptionTitle || '',
          }}
          isGlobalPromo={isGlobalPromo}
        />
        </div>
        {popup && (
          <div
            className="tooltip"
            role="presentation"
            onClick={() =>
              setModal({
                title: popupTitle || pOptionTitle || '',
                text: popup,
                show: true,
              })
            }
          >
            <ProductCardSpecItemAction>
              <Svg name="info" height={16} width={16} />
            </ProductCardSpecItemAction>
          </div>
        )}
      </OptionContainer>

      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={() => setModal({ show: false, text: '', title: '' })}
      >
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
            </ModalTitle>
          )}
          <ModalContent>
            <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
          </ModalContent>
        </>
      </VfModal>
    </>
  );
};

export default Option;
