import { Children, useEffect, useRef, useState } from 'react';
import { ImageDivider, CustomText } from '@vfit/shared/atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { useDeviceType } from '@vfit/shared/hooks';
import { Fade } from 'react-awesome-reveal';
import { fonts } from '@vfit/shared/themes';
import { overrideProductList, useProductListTagging } from '@vfit/consumer/data-access';
import {
  ProductSolutionsContainer,
  ProductSolutionsText,
  DisclaimerContainer,
} from './productsSolutions.style';
import { IProductsSolutionsProps } from './productsSolutions.models';
import { getAllHeight, getSlideSize, updateAllMobileHeight } from './productsSolutions.utils';
import ProductCard from '../ProductCard/productCard';

const ProductsSolutions = ({
  solutions,
  containerStyle,
  classKey,
  onTrack,
  showCase,
  divider,
  disclaimer,
  isR02,
  panicModeConfiguration,
  globalPromo,
}: IProductsSolutionsProps) => {
  const productSolutionRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const [allMobileHeight, setAllMobileHeight] = useState<number[]>([]);
  const haveMarketingLabel = !!solutions?.products.find((p) => !!p.marketingLabel);
  const { trackProductLink } = useProductListTagging();
  const products = overrideProductList(solutions?.products, panicModeConfiguration, globalPromo);

  const setMobileHeight = () => {
    if (productSolutionRef.current) {
      const allHeight = getAllHeight(productSolutionRef.current);
      setAllMobileHeight(allHeight);
      if (isMobile) {
        updateAllMobileHeight(productSolutionRef.current, allHeight, 0);
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setMobileHeight();
      }, 100);
    }
  }, [isMobile]);

  const swiperSolutions = (type: 'slide' | 'cards') => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      spaceBetween={33}
      freeMode={false}
      mousewheel={{
        forceToAxis: true,
      }}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      style={{
        height: 'fit-content',
      }}
      onSlideChange={(swiper) => {
        if (isMobile && productSolutionRef?.current) {
          updateAllMobileHeight(productSolutionRef.current, allMobileHeight, swiper.activeIndex);
          trackProductLink(products?.[swiper.activeIndex]);
        }
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        products?.map((product, index) => {
          let marginLeft = 0;
          if (index === 0) {
            if (isDesktop) marginLeft = 80;
            else if (isTablet) marginLeft = 32;
          }
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(index, isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                ...customSize,
              }}
            >
              <div>
                <ProductCard
                  cardIndex={index}
                  onTrack={onTrack}
                  showCase={showCase}
                  product={product}
                  classKey={classKey}
                  noMargin={isMobile}
                  haveMarketingLabel={haveMarketingLabel}
                  isR02={isR02}
                />
              </div>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  if (!solutions.products || solutions?.products?.length === 0) return null;

  return (
    <>
      <ProductSolutionsContainer style={{ ...containerStyle }} ref={productSolutionRef}>
        {solutions.description && (
          <ProductSolutionsText>
            <CustomText text={solutions.description} />
          </ProductSolutionsText>
        )}
        {isMobile && swiperSolutions('cards')}
        {!isMobile && swiperSolutions('slide')}
      </ProductSolutionsContainer>
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText
            text={disclaimer}
            lineHeight={22}
            size={16}
            textAlign="left"
            fontFamily={`${fonts.regular}`}
          />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default ProductsSolutions;
