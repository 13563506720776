import { ICustomSize, ICustomSizeValues } from '@vfit/shared/models';

const DEFAULT_SIZE: ICustomSize = {
  default: {
    width: 405,
  },
  tablet: {
    width: 329,
  },
  mobile: {
    width: 329,
  },
};

const getSlideSize = (
  slideIndex: number,
  isMobile: boolean,
  isTablet: boolean
): ICustomSizeValues => {
  if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
  return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};

export { getSlideSize, DEFAULT_SIZE };
