import { IPriceLiteProps } from './priceLite.models';
import {
  Detail,
  DetailsContainer,
  OriginalPrice,
  Price,
  PriceContainer,
  PriceLabel,
  PriceWrapper,
} from './priceLite.style';

const PriceLite = ({ price, priceLabel, originalPrice, recurrence }: IPriceLiteProps) => {
  const splitPrice = price?.split(',');

  return (
    <PriceWrapper>
      {priceLabel && <PriceLabel>{priceLabel}</PriceLabel>}
      {originalPrice && <OriginalPrice>{originalPrice}</OriginalPrice>}
      <PriceContainer>
        <Price>{splitPrice[0]}</Price>
        <DetailsContainer>
          {splitPrice.length > 1 && <Detail>{`,${splitPrice[1]}`}</Detail>}
          {recurrence && <Detail>{`/${recurrence}`}</Detail>}
        </DetailsContainer>
      </PriceContainer>
    </PriceWrapper>
  );
};

export default PriceLite;
