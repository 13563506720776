import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import {fontWeight} from "html2canvas/dist/types/css/property-descriptors/font-weight";

export const OptionContainer = styled.div<{ compact?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => (props.compact ? '0' : '8px')} !important;

  span {
    font-family: ${fonts.regular};
    ${pxToCssFont(12, 16)}
    font-style: normal;
    font-weight: 400;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(14, 18)}
    }
  }

  .tooltip {
    margin-top: ${(props) => (props.compact ? '0 !important' : '')};
    margin-left: ${(props) => (props.compact ? '0 !important' : '')};
  }
`;

export const DisclaimerContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 0 !important;

  span {
    font-family: ${fonts.regular};
    ${pxToCssFont(12, 16)}
    font-style: normal;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(14, 18)}
    }
  }
`;

export const ProductCardSpecItemAction = styled.button`
  display: flex;
  border: none;
  flex-shrink: 0;
  padding: 0;
  margin: 0 0 0 16px !important;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const Title = styled.span<{ isGlobalPromo?: boolean }>`
  p {
    margin: 0;
  }
  font-family: ${fonts.vodafone} !important;
  font-style: normal;

  ${({ isGlobalPromo }) =>
    isGlobalPromo
      ? `
        && {
          ${pxToCssFont(16, 22)}
          font-weight: 800;

          @media (min-width: ${breakpoints.desktop}) {
            ${pxToCssFont(18, 24)}
          }
        }
      `
      : `
        ${pxToCssFont(12, 16)}
        font-weight: 400;

        @media (min-width: ${breakpoints.desktop}) {
          ${pxToCssFont(14, 18)}
        }
      `}
`;


export const Disclaimer = styled.span`
  p {
    margin: 0;
  }
  font-family: ${fonts.vodafone};
  font-style: normal;
  font-weight: 700;
  &&{
    ${pxToCssFont(14, 18)};

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(16, 22)}

    }
  }
`;
