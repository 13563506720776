import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding-top: 5px;
  gap: 4px;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  height: 32px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-evenly;
`;

export const Price = styled.div`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(44, 32)};
`;

export const Detail = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(16, 14)};
`;

export const PriceLabel = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 400;
  ${pxToCssFont(16, 12)};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(14, 12)};
  }
`;

export const OriginalPrice = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 400;
  ${pxToCssFont(16, 12)};
  text-decoration: line-through;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(14, 12)};
  }
`;

