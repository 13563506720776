import { Children } from 'react';
import { IProductOptipns } from './productOptions.models';
import Option from './components/Option/option';

const ProductOptions = ({
                          isShoppingCart,
                          inflactionCartView,
                          inflactionHub,
                          isGlobalPromoActive,
                        }: IProductOptipns) => {
  if (isShoppingCart && inflactionCartView && inflactionCartView[0]?.text) {
    return Children.toArray(
      inflactionCartView.map((infationCart) => (
        <Option
          optionTitle={infationCart?.text}
          popup={infationCart?.popup}
          color={infationCart?.color}
          optionDisclaimer={infationCart?.disclaimer}
          isGlobalPromo={isGlobalPromoActive}
        />
      ))
    );
  }
  if (inflactionHub && inflactionHub[0]?.text) {
    return Children.toArray(
      inflactionHub.map((inflation) => (
        (inflation?.text || inflation.disclaimer) &&
        <Option
          optionDisclaimer={inflation?.disclaimer}
          optionTitle={inflation?.text}
          popup={inflation?.popup}
          color={inflation?.color}
          isGlobalPromo={isGlobalPromoActive}
        />
      ))
    );
  }
  return undefined;
};

export default ProductOptions;
