import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToFontSize,
  pxToLineHeight,
} from '@vfit/shared/themes';

export interface IProductCardTopImage {
  large?: boolean;
  isClickable?: boolean;
}

interface IProductCardContainer {
  noMargin: boolean;
  haveOfferLabel?: boolean;
}

export const ProductCardContainer = styled.div<IProductCardContainer>`
  display: flex;
  flex: 1;
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  margin-left: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  position: ${({ haveOfferLabel }) => (haveOfferLabel ? 'relative' : 'inherit')};
  bottom: ${({ haveOfferLabel }) => (haveOfferLabel ? '40px' : 'inherit')};

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
  }
`;

export const ProductCardTopImage = styled.div<IProductCardTopImage>`
  display: flex;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'inherit')};
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 170px;
  position: relative;
  border-radius: 20px 20px 0 0;
  padding-left: 24px;
  padding-right: 24px;
  transition: transform 0.9s ease-in-out;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  @media (min-width: ${breakpoints.tablet}) {
    min-height: ${({ large }) => (large ? '300px' : '170px')};
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: ${({ large }) => (large ? '300px' : '240px')};
    padding-left: 36px;
    padding-right: 36px;
  }
`;

export const ProductCardBottom = styled.div<{ haveBorderRadius?: boolean }>`
  z-index: 2;
  background: ${colors.$ffffff};
  border: 1px solid #ccc;
  border-radius: ${({ haveBorderRadius }) =>
    haveBorderRadius ? '20px 20px 20px 20px' : '0 0 20px 20px'};
`;

export const ProductCardBottomContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 32px;
    margin-right: 32px;
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const SpecsLockInDesc = styled.div`
  div {
    margin: 0;
  }
`;

export const Footer = styled.div`
  div {
    margin: 24px 0 0;
  }
`;

export const H3Header = styled.div`
  font-family: ${fonts.exbold};
  font-style: normal;
  font-weight: 800;
  ${pxToCssFontSize(30)}
  color: ${colors.$ffffff};
  margin: 0;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(36)}
  }
`;

export const H2Header = styled.div`
  text-transform: uppercase;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 700;
  ${pxToCssFontSize(14)}
  color: ${colors.$ffffff};
  margin: 0;
  margin-bottom: 4px;
  margin-top: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(14)}
    margin-bottom: 4px;
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(18)}
    margin-bottom: 7px;
    margin-top: 33px;
  }
`;

export const ProductCardTopImageContent = styled.div`
  align-items: flex-end;

  h2 {
    text-transform: uppercase;
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFontSize(14)}
    color: ${colors.$ffffff};
    margin: 0;
    margin-bottom: 4px;
    margin-top: 24px;
  }

  h3 {
    font-family: ${fonts.exbold};
    font-style: normal;
    font-weight: 400;
    ${pxToCssFontSize(30)}
    color: ${colors.$ffffff};
    margin: 0;
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h3 {
      ${pxToCssFontSize(30)}
    }

    h2 {
      ${pxToCssFontSize(14)}
      margin-bottom: 4px;
      margin-top: 24px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h3 {
      ${pxToCssFontSize(42)}
    }

    h2 {
      ${pxToCssFontSize(18)}
      margin-bottom: 7px;
      margin-top: 33px;
    }
  }
`;

export const DivImgContainer = styled.div<{ absolute: boolean; zoomIn?: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 15px 15px 0 0;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  img {
    transition: transform 0.9s ease-in-out;
  }

  &:hover {
    img {
      transform: ${({ zoomIn }) => (zoomIn ? 'scale(1.5)' : 'inherit')};
    }
  }
`;

export const ProductCardSpecItem = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0;
  margin-bottom: 8px;

  div {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(20, 30)}
    color: ${colors.$262626};
    margin: 0;

    img {
      width: 19px;
    }
  }

  svg {
    margin-right: 16px;
    width: 16px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 16px;
  }
`;

export const ProductCardBottomContainerText = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  h6 {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    color: ${colors.$262626};
    margin: 0;
  }

  .productTitle div {
    margin: 0;
    font-family: ${fonts.exbold};
    color: ${colors.$262626};
    font-weight: 400;
    display: flex;
    align-items: baseline;

    .price {
      ${pxToCssFont(30, 38)}

      @media (min-width: ${breakpoints.desktop}) {
        white-space: nowrap;
        ${pxToCssFont(42, 52)}
      }
    }

    .rec {
      ${pxToCssFont(24, 28)}

      @media (min-width: ${breakpoints.desktop}) {
        ${pxToCssFont(36, 45)}
      }
    }
  }

  h4 {
    margin: 0;
    font-family: ${fonts.regular} !important;
    color: ${colors.$7e7e7e} !important;
    font-weight: 700 !important;
    font-size: ${pxToFontSize(18)} !important;
    line-height: ${pxToLineHeight(18, 24)} !important;
  }

  .productDescription div {
    margin: 0;
    margin-top: 10px;
    color: ${colors.$0d0d0d};
    p {
      margin: 0 !important;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .productTitle div {
      ${pxToCssFontSize(24)}
    }

    h4 {
      ${pxToCssFontSize(16)}
    }

    h6 {
      ${pxToCssFontSize(18)}
    }

    .productDescription div {
      ${pxToCssFontSize(14)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .productTitle div {
      ${pxToCssFontSize(36)}
    }

    h4 {
      ${pxToCssFontSize(20)}
      font-family: ${fonts.light};
    }

    .productDescription div {
      ${pxToCssFontSize(14)}
    }
  }
`;

export const ProductCardSpecContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: baseline;
  justify-content: space-between;
`;

export const ProductCardSpecTitle = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFontSize(18)}
  color: ${colors.$262626};
  text-transform: uppercase;
`;

export const ProductCardSpecItemAction = styled.button`
  display: flex;
  border: none;
  flex-shrink: 0;
  padding: 0;
  margin: 0 0 0 16px !important;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;
`;

export const SpecsLockInContainer = styled.li`
  display: flex;
  align-items: center;

  :not(:nth-last-child(2)) {
    margin-bottom: 24px;
  }
`;
