import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border: 1px solid;
  border-color: ${colors.$bebebe};
  border-radius: 20px;

  @media (min-width: ${breakpoints.desktop}) {
    width: 296px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 8px 16px;
  border-radius: 0 0 19px 19px;
  background-color: ${colors.$f2f2f2};
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 0 8px;
`;

export const PriceRecurrenceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
`

export const Price = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(32, 32)};
`;

export const Recurrence = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(20, 28)};
`;

export const Subprice = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 400;
  ${pxToCssFont(14, 18)};
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;

  svg {
    width: 11.35px;
    height: 8px;
    object-fit: contain;
  }

  span {
    font-family: ${fonts.vodafone};
    font-weight: 400;
    ${pxToCssFont(16, 22)};
  }

  p {
    margin: 0;
  }
`;

