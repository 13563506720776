import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  ProductCardBottom,
  ProductCardBottomContainer,
  ProductCardBottomContainerText,
} from '../../productCardLite.style';
import {
  ProductCardTopImageLoader,
  ProductCardTextLoader,
  ProductCardListLoader,
  ProductCardListLoaderEmpty,
} from './productCardLoader.style';

interface IProductCardLoader {
  lottieFile?: string;
}

const ProductCardLoader = ({ lottieFile }: IProductCardLoader) => (
  <>
    <ProductCardTopImageLoader />
    <ProductCardBottom>
      <ProductCardBottomContainer>
        {!lottieFile && (
          <>
            <ProductCardBottomContainerText>
              <ProductCardTextLoader />
            </ProductCardBottomContainerText>
            <div>
              <ProductCardListLoader />
              <ProductCardListLoader />
              <ProductCardListLoader />
              <ProductCardListLoader />
            </div>
          </>
        )}
        {lottieFile && (
          <>
            <ProductCardBottomContainerText>
              <Player
                src={requireStaticImageAtomComponent(lottieFile).src}
                className="player"
                loop
                autoplay
              />
            </ProductCardBottomContainerText>
            <div>
              <ProductCardListLoaderEmpty />
              <ProductCardListLoaderEmpty />
            </div>
          </>
        )}
      </ProductCardBottomContainer>
    </ProductCardBottom>
  </>
);

export default ProductCardLoader;
