import { Button } from '@vfit/shared/atoms';
import { ICTAContainerProps } from './productCardCTAContainer.models';
import { AboveLabel, Wrapper } from './productCardCTAContainer.style';

const ProductCardCTAContainer = ({
  aboveText,
  firstButtonAction,
  firstButtonLabel,
  firstButtonName,
  secondButtonAction,
  secondButtonLabel,
  secondButtonName,
  goDetailButtonAction,
  goDetailButtonLabel,
  goDetailButtonName,
}: ICTAContainerProps) => (
  <Wrapper>
    {aboveText && <AboveLabel>{aboveText}</AboveLabel>}
    <Button variant="primary" onClick={firstButtonAction} name={firstButtonName || ''}>
      {firstButtonLabel}
    </Button>
    {secondButtonLabel && secondButtonAction && (
      <Button variant="secondary" onClick={secondButtonAction} name={secondButtonName || ''}>
        {secondButtonLabel}
      </Button>
    )}
    {goDetailButtonLabel && goDetailButtonAction && (
      <Button variant="secondary" onClick={goDetailButtonAction} name={goDetailButtonName || ''}>
        {goDetailButtonLabel}
      </Button>
    )}
  </Wrapper>
);

export default ProductCardCTAContainer;
