import { Children } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import { Check } from '@vfit/shared-icons';
import { IPropositionCardProps } from './propositionCard.models';
import {
  BottomContainer,
  ListItem,
  Price,
  PriceContainer,
  PriceRecurrenceContainer,
  Recurrence,
  Subprice,
  TopContainer,
  Wrapper,
} from './propositionCard.style';

const PropositionCard = ({
  price,
  recurrence,
  subprice,
  cornerColor,
  cornerImage,
  items,
}: IPropositionCardProps) => {
  return (
    <Wrapper>
      <TopContainer>
        <PriceContainer>
          <PriceRecurrenceContainer>
            <Price>{price}</Price>
            {recurrence && <Recurrence>{recurrence}</Recurrence>}
          </PriceRecurrenceContainer>
          <Subprice dangerouslySetInnerHTML={{ __html: subprice || '' }} />
        </PriceContainer>
        <ImageAtoms
          image={cornerImage}
          nameCard="cornerImage"
          style={{
            objectFit: 'contain',
            width: '100px',
            height: '26px',
            backgroundColor: cornerColor || '#FFF',
            borderBottomLeftRadius: '20px',
            borderTopRightRadius: '19px'
          }}
        />
      </TopContainer>
      <BottomContainer>
        {items &&
          Children.toArray(
            items?.map((item) => (
              <ListItem>
                <Check />
                <span dangerouslySetInnerHTML={{ __html: item || '' }} />
              </ListItem>
            ))
          )}
      </BottomContainer>
    </Wrapper>
  );
};

export default PropositionCard;

