import styled from 'styled-components';
import {
  colors,
  fonts,
  pxToCssFont,
} from '@vfit/shared/themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const FirstLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 18px;
`;

export const OfferAllowanceLabel = styled.span`
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(24, 28)};

  p {
    margin: 0;
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  font-family: ${fonts.vodafone};
  font-weight: 800;
  ${pxToCssFont(32, 24)};

  span {
    height: 1px;
    background-color: ${colors.$bebebe};
  }
`;
