import { ICarouselCMS, IDevice, IProduct, IWidgetType } from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';

const getProductsShowedInHub = (
  widgets: ICarouselCMS[],
  customHubs?: { [key: string]: IProduct[] | IDevice[] } | undefined
) => {
  const customHub = widgets?.find(
    (e) => e.type === IWidgetType.HUB_CUSTOM || e.type === IWidgetType.HUB_CUSTOM_LITE
  );
  const customHubSolution = widgets?.find(
    (e) =>
      e.type === IWidgetType.HUB_SOLUTIONS_CUSTOM ||
      e.type === IWidgetType.HUB_SOLUTIONS_CUSTOM_LITE
  );
  const productsInHub: IProduct[] = [];
  const productsInHubSolution: IProduct[] = [];

  if (customHub) {
    customHub?.elements?.forEach((element) => {
      if (
        element.key &&
        element.id &&
        customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`]
      ) {
        productsInHub.push(customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`] as IProduct);
      }
    });
  }

  if (customHubSolution) {
    customHubSolution?.elements?.forEach((element) => {
      if (
        element.key &&
        element.id &&
        customHubSolution?.[element.key.toLowerCase()]?.[`_${element.id}`]
      ) {
        productsInHubSolution.push(
          customHubSolution?.[element.key.toLowerCase()]?.[`_${element.id}`] as IProduct
        );
      }
    });
  }

  return SoftManagerService().getSoftProductsByProducts([
    ...productsInHub,
    ...productsInHubSolution,
  ]);
};

export { getProductsShowedInHub };
