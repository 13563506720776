import { Children, useRef } from 'react';
import { ImageDivider, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from 'react-awesome-reveal';
import { fonts } from '@vfit/shared/themes';
import { useProductListTagging } from '@vfit/consumer/data-access';
import { IProductsListProps } from './productsListLite.models';
import {
  ProductListContainer,
  MainContainer,
  DisclaimerContainer,
  Title,
} from './productsListLite.style';
import { getSlideSize } from './productsListLite.utils';
import ProductCardLite from '../ProductCardLite/productCardLite';

const ProductsListLite = ({
  title,
  products,
  zoomIn = false,
  containerStyle,
  classKey,
  showCase,
  onTrack,
  divider,
  disclaimer,
  isR02,
}: IProductsListProps) => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const productListsElements = useRef<HTMLDivElement>(null);
  const haveMarketingLabel = !!products.find((p) => !!p.marketingLabel);
  const { trackProductLink } = useProductListTagging();
  const divRef = useRef<HTMLDivElement | null>(null);
  const haveHeaderLight =
    (divRef?.current?.parentNode?.previousSibling as HTMLElement)?.getAttribute('id') ===
      'header-lite' || !!document.getElementById('header-lite');

  const swiperProduct = (type: 'slide' | 'cards', spaceBetween = 33) => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      spaceBetween={spaceBetween}
      freeMode={false}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      onSlideChange={(swiper) => {
        if (isMobile && productListsElements?.current) {
          trackProductLink(products?.[swiper.activeIndex]);
        }
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        products?.map((product, index) => {
          let marginLeft = 0;
          if (index === 0) {
            if (isDesktop) marginLeft = 80;
            else if (isTablet) marginLeft = 32;
          }
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(index, isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                ...customSize,
              }}
            >
              <div>
                <ProductCardLite
                  onTrack={onTrack}
                  showCase={showCase}
                  cardIndex={index}
                  product={product}
                  zoomIn={zoomIn}
                  classKey={classKey}
                  noMargin={isMobile}
                  haveMarketingLabel={haveMarketingLabel}
                  isR02={isR02}
                />
              </div>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  const listProductsMobile = () => {
    return (
      <div
        style={{
          padding: 30,
        }}
      >
        {Children.toArray(
          products?.map((product, index) => {
            return (
              <ProductCardLite
                onTrack={onTrack}
                showCase={showCase}
                cardIndex={index}
                product={product}
                zoomIn={zoomIn}
                classKey={classKey}
                noMargin={isMobile}
                haveMarketingLabel={haveMarketingLabel}
                isR02={isR02}
              />
            );
          })
        )}
      </div>
    );
  };

  return (
    <>
      {title && (
        <Title haveHeaderLight={haveHeaderLight}>
          <CustomText
            text={title}
            size={30}
            lineHeight={38}
            sizeMobile={30}
            lineHeightMobile={38}
            margin={[0, 0, 0, 0]}
          />
        </Title>
      )}
      <ProductListContainer style={{ ...containerStyle }} ref={productListsElements}>
        <MainContainer>
          {!isDesktop && listProductsMobile()}
          {isDesktop && swiperProduct('slide')}
          {disclaimer && (
            <DisclaimerContainer>
              <CustomText
                text={disclaimer}
                lineHeight={22}
                size={16}
                textAlign="left"
                fontFamily={`${fonts.regular}`}
              />
            </DisclaimerContainer>
          )}
        </MainContainer>
      </ProductListContainer>
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default ProductsListLite;
