import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ImageAtoms, CustomText } from '@vfit/shared/atoms';
import {
  CMS_CONFIG,
  getRedirectCoverage,
  IN_ACTIVATION,
  retrieveInTest,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import { useRedirectCoverageToolFlow } from '@vfit/consumer/hooks';
import { useQueryClient } from 'react-query';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  IProductLead,
  LoggerInstance,
  useTracking,
  volaModalManager,
} from '@vfit/shared/data-access';
import {
  IActionType,
  IClickToCallMeData,
  IErrorFormApi,
  IGradientImage,
  ISubmitOutput,
} from '@vfit/shared/models';
import { Pills, VfModal } from '@vfit/shared/components';
import { fonts, purify } from '@vfit/shared/themes';
import {
  API,
  IGetAllCmsForm,
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  openLeadModal,
  openPopup,
  PAGES,
} from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { CoverageToolModal } from '@vfit/consumer/components';
import {
  ModalContent,
  ModalTitle,
  ProductCardBottom,
  ProductCardBottomContainer,
  ProductCardContainer,
  ProductCardTopImage,
  ProductCardTopImageContent,
  DivImgContainer,
  H3Header,
  H2Header,
  OptionListContainer,
} from './productCardLite.style';
import { IProductCardLiteProps } from './productCardLite.models';
import ProductCardLoader from './components/ProductCardLoader/productCardLoader';
import ProductCardTopContainer from './components/ProductCardTopContainer/productCardTopContainer';
import ProductMarketingLabel from './components/ProductMarketingLabel/productMarketingLabel';
import ProductOptions from './components/ProductOptions/productOptions';
import ProductCardCTAContainer from './components/ProductCardCTAContainer/productCardCTAContainer';

const ProductCardLite = ({
  noMargin = false,
  isLoading = false,
  product: originalProduct,
  large,
  zoomIn,
  onActive,
  hideActions,
  setSpecHeight,
  showCase,
  onTrack,
  cardIndex,
  haveMarketingLabel,
  isR02,
  isShoppingCart,
}: IProductCardLiteProps) => {
  const { page } = usePage();
  const { asPath, push } = useRouter();
  const specRef = useRef<HTMLDivElement | null>(null);
  const isInTest = retrieveInTest() && originalProduct?.enableAbTest;
  const abTestConfiguration = originalProduct.offerDetailABTest || {};
  const product = !isInTest
    ? originalProduct
    : {
        ...originalProduct,
        ...abTestConfiguration,
      };
  const {
    title,
    priceLiteLabel,
    image,
    price,
    originalPrice,
    recurrence,
    subprice,
    description,
    action,
    hubSecondAction,
    buttons,
    pills,
    slug,
    category,
    queryParamGoDetail,
    redirectR02,
    inflactionHub,
    inflactionCartView,
    isGlobalPromoActive,
    cmsId,
    offerAllowance,
    aboveCtasHubLabel,
  } = product;
  const [pDescription] = purify([description || '']);
  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const [isInActivation] = useRedirectCoverageToolFlow(cmsId || '');
  const { isMobile, isDesktop } = useDeviceType();
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });
  const detailLabel = buttons && buttons?.length > 0 ? buttons?.[0].label || '' : '';
  const queryClient = useQueryClient();
  const volaClickToCallData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;

  const { trackWithNewDatalayer } = useTracking({
    event: ['product_view'],
    disableInitialTrack: true,
  });

  const handlerTracking = () => {
    if (typeof cardIndex === 'number' && typeof showCase === 'number' && onTrack)
      LoggerInstance.debug('Handler track');
  };

  useEffect(() => {
    if (!isShowCoverageTool && isInActivation) {
      setIsShowCoverageTool(true);
    }
  }, [isInActivation]);

  useEffect(() => {
    if (specRef?.current?.offsetHeight && setSpecHeight) {
      setSpecHeight(specRef.current?.offsetHeight);
    }
  }, [setSpecHeight, specRef]);

  const goDetail = () => {
    handlerTracking();
    if (slug) {
      const pathWithoutParams = asPath.split('?')[0];
      const queryParamsDetail =
        queryParamGoDetail && queryParamGoDetail?.length > 0 ? queryParamGoDetail.join('&') : null;
      let href = `${pathWithoutParams}/${slug}`;
      if (queryParamsDetail) {
        href += `?${queryParamsDetail}`;
      }
      window.location.href = href;
    }
  };

  const activeNow = () => {
    handlerTracking();
    if (onActive) {
      onActive();
      return;
    }
    let allLeads;
    let refLead;
    let lead;
    switch (action?.type) {
      case IActionType.CUSTOM_URL:
        if (isR02?.() && redirectR02) {
          window.open(redirectR02, action?.isBlank === 'true' ? '_blank' : '_self');
        } else if (action?.url) {
          window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.CALL_ME_NOW:
        if (action?.callMeNow) openPopup(action.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (action?.title && action?.url) {
          volaModalManager.handleVolaModal({
            url: action?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
            taggingOption: page?.params?.elements?.taggingOptions,
            pageProduct: SoftManagerService(queryClient).getPageProduct(product),
          });
        }
        break;
      case IActionType.COVERAGE_TOOL:
        setIsShowCoverageTool(true);
        break;
      case IActionType.CHECKOUT_MODAL_MOBILE:
        localStorage.setItem('tProd', JSON.stringify(product));
        window.location.href = getRedirectCoverage();
        break;
      case IActionType.COVERAGE_TOOL_WITH_REDIRECT:
        if (action?.url && product?.cmsId) {
          const alreadyHaveParams = action.url.includes('?');
          window.location.href = alreadyHaveParams
            ? `${action.url}&${IN_ACTIVATION}=${product?.cmsId || ''}`
            : `${action.url}?${IN_ACTIVATION}=${product?.cmsId || ''}`;
        }
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = action?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
            onSuccess: (submitOutput: ISubmitOutput) =>
              manageSuccessLeadPlatform(submitOutput, push, queryClient),
            onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
              manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
          });
        }
        break;
      default:
        break;
    }
  };

  const handleHubAction = () => {
    const type = hubSecondAction?.type ? +hubSecondAction.type : -1;
    let allLeads;
    let refLead;
    let lead;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (hubSecondAction?.url)
          window.open(
            hubSecondAction.url,
            hubSecondAction?.isBlank === 'true' ? '_blank' : '_self'
          );
        break;
      case IActionType.CALL_ME_NOW:
        if (hubSecondAction?.callMeNow) openPopup(hubSecondAction.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (hubSecondAction?.title)
          if (hubSecondAction?.title && hubSecondAction?.url)
            volaModalManager.handleVolaModal({
              url: hubSecondAction?.url || '',
              cmsClickToCallData: { ...volaClickToCallData },
              taggingOption: page?.params?.elements?.taggingOptions,
              pageProduct: SoftManagerService(queryClient).getPageProduct(product),
            });
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = hubSecondAction?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
            product: SoftManagerService(queryClient).getPageProduct(product) as IProductLead,
            onSuccess: manageSuccessLeadPlatform,
            onError: manageErrorLeadPlatform,
          });
        }
        break;
      default:
        break;
    }
  };

  const hideCoverageTool = () => {
    const pageDatalayer: any = queryClient.getQueryData('pageDatalayer');
    if (pageDatalayer) {
      trackWithNewDatalayer({
        event: pageDatalayer?.event,
        event_label: pageDatalayer?.event_label,
        opts: pageDatalayer?.opts,
        pageProduct: pageDatalayer?.pageProduct,
        cartProduct: pageDatalayer?.cartProduct,
        visitorTrackingOpts: pageDatalayer?.visitorTrackingOpts,
      });
    }
    setIsShowCoverageTool(false);
  };

  const clickImage = () => {
    if (!product.disableClickDetail) goDetail();
  };

  return (
    <>
      {(((product?.marketingLabel || product?.marketingSecondaryLabel) && !isDesktop) ||
        (haveMarketingLabel && isDesktop)) && (
        <ProductMarketingLabel
          firstMarketingLabel={product?.marketingLabel}
          secondMarketingLabel={product?.marketingSecondaryLabel}
          firstMarketingLabelColor={product?.marketingLabelColor}
          secondMarketingLabelColor={product?.marketingSecondaryLabelColor}
          firstMarketingBackgroundColor={product?.marketingLabelBackgroundColor}
          secondMarketingBackgroundColor={product?.marketingSecondaryBackgroundColor}
        />
      )}
      <ProductCardContainer noMargin={noMargin} haveOfferLabel={haveMarketingLabel}>
        {isLoading && <ProductCardLoader />}
        {!isLoading && (
          <>
            {isDesktop && (
              <ProductCardTopImage
                large={large}
                isClickable={!product?.disableClickDetail}
                onClick={clickImage}
              >
                <Pills pills={pills || []} />
                <ProductCardTopImageContent>
                  <H2Header>{category}</H2Header>
                  <H3Header>{title}</H3Header>
                </ProductCardTopImageContent>
                <DivImgContainer absolute zoomIn={zoomIn}>
                  <ImageAtoms
                    gradient={IGradientImage.GRADIENT_BOTTOM_40}
                    nameCard="topImage"
                    image={image}
                  />
                </DivImgContainer>
              </ProductCardTopImage>
            )}
            <ProductCardBottom haveBorderRadius={!isDesktop}>
              <ProductCardBottomContainer>
                <ProductCardTopContainer
                  offerAllowanceLabel={offerAllowance || []}
                  price={price}
                  recurrence={recurrence}
                  priceTitle={priceLiteLabel}
                  originalPrice={originalPrice}
                  subprice={subprice}
                />
                {pDescription && (
                  <div
                    className="productDescription"
                    dangerouslySetInnerHTML={{ __html: pDescription }}
                  />
                )}
                <OptionListContainer>
                  <ProductOptions
                    isShoppingCart={isShoppingCart}
                    inflactionCartView={inflactionCartView}
                    inflactionHub={inflactionHub}
                    isGlobalPromoActive={isGlobalPromoActive}
                  />
                </OptionListContainer>
                {!hideActions && (
                  <ProductCardCTAContainer
                    aboveText={aboveCtasHubLabel}
                    firstButtonAction={activeNow}
                    firstButtonLabel={action?.title || ''}
                    firstButtonName={`action_card_${!cardIndex ? '1' : cardIndex + 1}_${
                      product?.offerId?.toString() || ''
                    }`}
                    secondButtonAction={handleHubAction}
                    secondButtonLabel={hubSecondAction?.title || ''}
                    secondButtonName={`secondAction_card_${!cardIndex ? '1' : cardIndex + 1}_${
                      product?.offerId?.toString() || ''
                    }`}
                    goDetailButtonAction={goDetail}
                    goDetailButtonLabel={detailLabel}
                    goDetailButtonName={`detailAction_card_${!cardIndex ? '1' : cardIndex + 1}_${
                      product?.offerId?.toString() || ''
                    }`}
                  />
                )}
              </ProductCardBottomContainer>
            </ProductCardBottom>
          </>
        )}
        <VfModal
          height={563}
          isOpen={modal.show}
          handleClose={() => setModal({ show: false, text: '', title: '' })}
        >
          <>
            {modal.title && (
              <ModalTitle>
                <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
            </ModalContent>
          </>
        </VfModal>
        <CoverageToolModal
          backgroundImage={product.image}
          isOpen={isShowCoverageTool}
          product={product}
          handleClose={hideCoverageTool}
        />
      </ProductCardContainer>
    </>
  );
};

export default ProductCardLite;
