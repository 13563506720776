import  * as S  from './productMarketingLabel.style';
import { IProductMarketingLabel } from './productMarketingLabel.models';

const ProductMarketingLabel = ({
  firstMarketingLabel,
  secondMarketingLabel,
  firstMarketingLabelColor,
  secondMarketingLabelColor,
  secondMarketingBackgroundColor,
  firstMarketingBackgroundColor
}: IProductMarketingLabel) => {
  const haveLabel : boolean = !!firstMarketingLabel || !!secondMarketingLabel;
  const isDoubleMarketingTag : boolean = !!firstMarketingLabel && !!secondMarketingLabel;

  return (
    <>
      {!isDoubleMarketingTag &&
        <S.ProductCardOfferLabel haveLabel={haveLabel} backgroundColor={firstMarketingBackgroundColor} textColor={firstMarketingLabelColor}>
          {firstMarketingLabel && <span>{firstMarketingLabel}</span>}
        </S.ProductCardOfferLabel>
      }
      {isDoubleMarketingTag &&
        <S.ProductCardOfferDoubleLabel>

          <S.FirstMarketingCard textColor={firstMarketingLabelColor} backgroundColor={firstMarketingBackgroundColor}>
            <span>{firstMarketingLabel}</span>
          </S.FirstMarketingCard>

          <S.SecondMarketingCard textColor={secondMarketingLabelColor} backgroundColor={secondMarketingBackgroundColor}>
            <span>{secondMarketingLabel}</span>
          </S.SecondMarketingCard>

        </S.ProductCardOfferDoubleLabel>
      }
    </>

  );
};

export default ProductMarketingLabel;
